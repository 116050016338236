
window.onload = function(){
    document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js-on ';
    
};

(function (doc, $) {
    
    $.ajaxPrefilter(function(options, originalOptions, xhr) { // this will run before each request
        var token = $('meta[name="csrf-token"]').attr('content'); // or _token, whichever you are using

        if (token) {
            return xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }
    });
        
    var emailLink = '';
    function findPos(obj) {
        var curleft = curtop = 0;
        if (obj && obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
        }
        return [curleft,curtop];
    }
    
    function stripAllHtmlTag(str){
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        str = tmp.textContent || tmp.innerText || "";
        return str;
    }
    
    var $window = $(window), flexslider;
    init = function () {
        
        var backBtn = function(){
            if (window.pageYOffset > 800) {
                $('.newsletter-back-btn').show();
            } else {
                $('.newsletter-back-btn').hide();
            }
        }
        backBtn();
        $(window).on('scroll', backBtn);
        $('.newsletter-back-btn').on('click', function(){
            $("html, body").animate({ scrollTop: 0 }); 
        });
        
        $("#preview-modal").fancybox({
            type        : "ajax",
            fitToView	: false,
            width	: '100%',
            height	: '100%',
            autoSize	: false,
            closeClick	: false,
            openEffect	: 'none',
            closeEffect	: 'none',
            beforeLoad : function(){
                
                this.ajax.data = $('#post-form').serialize();
                if (($('#pdf-preview').val() !== '' && typeof $('#pdf-preview').val() !== 'undefined') || $('.pdf-right-items').hasClass('pdf-right-items')) {
                    this.ajax.data = this.ajax.data + '&pdf_preview=1'
                }
                this.ajax.method = "post";
            },
            helpers: {
                overlay: {
                  locked: false
                }
            }
        });
       
        setTimeout(function() {
            if (window.location.hash) { 
                var hash = window.location.hash.substr(1); 
                var scrollPos = $('#'+hash+'-slow').offset().top;
                $("html, body").animate({ scrollTop: scrollPos }, 2000); 
                window.location.hash = 'disclaimer';
            }
        }, 1);
        
        $window.load(function() {

            /******* FLEXSLIDER ******/
            if (!$.flexslider) {
                return;
            }

            $('.flexslider').flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: true,
                itemWidth: 130,
                itemMargin: 0,
                minItems: 3,
                maxItems: 5
            });
            /***** FLEXSLIDER ******/

        });
        
        if ($('#admin').length) {
            $.trumbowyg.upload.serverPath = '/admin/editor/image'
            
            $('textarea').trumbowyg({
                btnsDef: {
                    image: {
                        dropdown: ['insertImage', 'upload'],
                        ico: 'insertImage'
                    }
                },
                // Redefine the button pane
                btns: ['viewHTML',
                        '|', 'formatting',
                        '|', 'btnGrp-semantic',
                        '|', 'link',
                        '|', 'image',
                        '|', 'btnGrp-justify',
                        '|', 'btnGrp-lists',
                        '|', 'horizontalRule',
                        '|', 'removeformat']
            }).on('tbwpaste', function(e){ 
                var elementTextarea = $(e.target);
                setTimeout( function() {
                    var text = stripAllHtmlTag(elementTextarea.val()); 
                    text = text.replace(/(<((?!br)[^>]+)>)/ig, '');
                    elementTextarea.val(text);
                    elementTextarea.prev().html(text);
                }, 100);
            });
        }

        if ($.sortable) {
          
            $( "#sortable" ).sortable({
                axis: "y",
                containment: "parent",
                handle: ".reorder-lawyer",
                update: function( event, ui ) {
                    var data = $(this).sortable('serialize');
                    
                    $.ajax({
                        data: {data: data, _token: $('#token').val()},
                        type: 'POST',
                        url: '/admin/lawyer/reorder',
                    });  
                }
            });
            $( "#sortable" ).disableSelection();
        }
          
          if ($('.photo-is-loaded').length) {
              $('.photo-uploaded-area').show();
          } else {
              $('.photo-upload-area').show();
          }
          

        /******* FILE UPLOAD ******/
            $(function () {

                if (!$('#admin').length) {
                    return;
                }
                'use strict';
                // Change this to the location of your server-side upload handler:
                var url = $('#fileupload').attr('data-url');
                $('#fileupload').fileupload({
                    url: url,
                    dataType: 'json',
                    submit: function (e, data) {
                        $.fenster('.jqFensterModal.upload').open();
                        data.formData = {oldImg: $('input#photo').val(), _token: $('#_token').val()};
                    },
                    done: function (e, data) {
                        var imgPath = $('.image-uploaded-admin').attr('data-path');
                        $('.image-uploaded-admin').html('<div class="delete-photo">x</div><img src="'+imgPath+data.result.files[0].name+'">')
                        setTimeout( function() {
                            $.fensterFinder($('.jqFensterModal.upload')).close();
                            $.fenster('.jqFensterModal.success-dialog').open();
                            $('#photo').val(data.result.files[0].name);
                        }, 1000);
                        $('.photo-upload-area .error-input').hide();
                        $('.photo-upload-area').hide();
                        $('.photo-uploaded-area').show();
                    
                    },
                    progressall: function (e, data) {
                        var progress = parseInt(data.loaded / data.total * 100, 10);
                        $('#progress .progress-bar').css(
                            'width',
                            progress + '%'
                        );
                    
                    },
                    error: function (request, status, error) {
                        $('.jq-text-midle').html('Sorry, but something went wrong: <b>'+error+'.</b> Try another photo!'); 
                        setTimeout( function() {
                            $.fensterFinder($('.jqFensterModal.upload')).close();
                        }, 5000);
                        
                    }
                    
                }).prop('disabled', !$.support.fileInput)
                    .parent().addClass($.support.fileInput ? undefined : 'disabled')
                    .bind('fileuploadadd', function(e, data) {
                        data.submitted = new Date().getTime();
                        data.submit();
                    }).bind('fileuploadprogress', function(e, data) {
                        var timeSpent = Math.round(((new Date().getTime() - data.submitted) / 1000));
                        $('.jq-text-midle').html('Elapsed time: '+timeSpent+' seconds'); 
                        });
            });
    /***** FILEUPLOAD *******/
    
    $(document).on('click', '.delete-photo', function(e) {
        var photo = $('#photo').val();
        $('#photo').val('');

        if (!$('#item-id').length) {
            $.post('/delete/photo', {'photo' : photo, _token: $('#_token').val(), status: 'delete'}).done(function(data) {
                $('.photo-upload-area').show();
                $('.photo-uploaded-area').hide();
            });
        } else {
            $.post('/delete/photo', {'photo' : photo, _token: $('#_token').val(), status: 'draft'}).done(function(data) {
                $('.photo-upload-area').show();
                $('.photo-uploaded-area').hide();
            });
        }
    });
    
    $(document).on('click', '#remove-pdf', function(e) {
        e.preventDefault();
        var href = $(e.target).attr('href');
        $.post(href).done(function(data) {
            if (data.success) {
                window.location.reload();
            } else {
                alert("Sorry, can't delete pdf! Try again later.");
            }
        });
       
    });
    
    if ($('#successfully-saved-lawyer').length
            || $('#successfully-saved-news').length
            || $('#successfully-saved-accolade').length
            || $('#successfully-saved-areas').length
            ) {
        $.fenster('.jqFensterModal.success-lawyer-saved').open(function () {
            this.getHolder().bind('jqFensterCallbackClose', function () {
                var url = $('.jqFensterModal.success-lawyer-saved').attr('data-url');
                window.location.href = url;
            });
        });
    }
    
    
    if ($('#successfully-delete-lawyer').length) {
        var name = $('#successfully-delete-lawyer').attr('data-name');
        var element = $('.jq-text-success-midle');
        name = name+' '+element.html();
        element.html(name);
        $.fenster('.jqFensterModal.success-lawyer-delete').open();
    }
    
    $('.table-delete-admin').on('click', function(e) {
       e.preventDefault();
       $('.delete-dialog .jq-delete-title').html($(this).attr('data-name'));
       $('.dialog-success-delete a').attr('href', $(this).attr('href'));
       $.fenster('.jqFensterModal.delete-dialog').open();
       
    });

    };
    var date = new Date();
    if ($.datepicker) {
        $('#datepicker').datepicker({dateFormat: "mm/dd/yy"});
    }
    
    $('a.print-button').on('click', function(e) {

        e.preventDefault();

        var $self = $(this);

        $.get('/news/print/' + $self.data('id'), function(content) {
            $(content).printThis({
                debug: true,               
                importCSS: true,            
                importStyle: false,         
                printContainer: true,       
                loadCSS: "/css/print.css",  
                pageTitle: $(this).find('._title').html(),              
                removeInline: false,        
                printDelay: 333,            
                header: null,          
                formValues: true            
            });
        });
    });
    
    $(".email-news").fancybox({
        maxWidth	: 400,
        maxHeight	: 450,
        fitToView	: false,
        width	: '70%',
        height	: '70%',
        autoSize	: false,
        closeClick	: false,
        openEffect	: 'none',
        closeEffect	: 'none',
        beforeLoad : function(){
            emailLink = $(this.element).attr("data-url");
        },
        helpers: {
            overlay: {
              locked: false
            }
        }
    });
    
    $(document).on('click', '.close-email-form', function(e) {
        e.preventDefault();
        $.fancybox.close();
    });
    
    $(document).on('click', '.search-button-main', function(e) {
        e.preventDefault();
        $('form#search-form').submit();
    });
    
    $(document).on('click', '.search-button', function(e) {
        e.preventDefault();
        $('form#search-form-big').submit();
    });
    
    $(document).on('click', '#practice-areas-menu', function(e) {
       e.preventDefault();
       $(e.target).toggleClass('hovered');
    });
    
    $('html').click(function (e) {
        if (e.target.id !== 'practice-areas-menu') {
            $('#practice-areas-menu').removeClass('hovered');
        } 
    });
    
    $(document).on('click', '#news-menu', function(e) {
       e.preventDefault();
       $(e.target).toggleClass('hovered');
    });
    
    $('html').click(function (e) {
        if (e.target.id !== 'news-menu') {
            $('#news-menu').removeClass('hovered');
        } 
    });
    
    $(document).on('click', '.send-email-form-button', function(e) {

        e.preventDefault();
        var link = emailLink;
        $("#link-to-send").val(link);
        var url = $(e.target).attr('href');
        if ($('.email-spiner').length === 0) {
            $('.jq-title').append('<img src="/img/spinner.gif" class="email-spiner">');
        
            $.post(url, $('form.send-email-form').serialize()).done(function(data) {
               $('.dialog-conteiner').replaceWith(data);
            });
        }
    });

    $(doc).ready(init);

}(this.document, this.jQuery));