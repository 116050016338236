
function findPos(obj) {
    var curleft = curtop = 0;
    if (obj.offsetParent) {
        do {
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
    }
    return [curleft,curtop];
}

(function($,sr){

    var debounce = function (func, threshold, execAsap) {
        var timeout;

        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            };

            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);

            timeout = setTimeout(delayed, threshold || 100);
        };
    }

    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

(function ($) {
    "use strict";

    //right-nav initialize
    var mySlidebars = new slidebars();
    mySlidebars.init();

    function eventHandler(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    $(mySlidebars.events).on({
        'closing': function (){
            $(".navbar-toggle").removeClass("active");
            $('#container').removeClass('no-scroll');
        },
        'opening': function (){
            $(".navbar-toggle").addClass("active");
            $('#container').addClass('no-scroll');
        }
    });

    $('.navbar-toggle').on('click', function(e) {
        eventHandler(e);
        mySlidebars.toggle('main-menu');
    });


    //enable boostrap popover
    $('[data-toggle="popover"]').popover();



    //fixed overviev
    var app_scrollbox = $("#container");
    var app_fixbox = app_scrollbox.find(".fixbox");

    if(!Modernizr.touch && app_fixbox.length) {
        var app_header = $("#header");
        var app_header_OffsetHeight = app_header.outerHeight();
        var app_fixbox_OffsetTop = findPos(app_fixbox[0])[1];

        var $fixboxClone = $("<div id=\"app-fixbox\"><div class=\"app-wrapper\"><div class=\"app-container\">"+ app_fixbox.clone()[0].outerHTML +"</div></div></div>");

        app_scrollbox.on("scroll", function(e){

            if((app_fixbox_OffsetTop - app_header_OffsetHeight) < this.scrollTop) {
                if(!$(".app-fixbox").length) $('#header').append($fixboxClone);
            } else {
                $fixboxClone.remove();
            }

        })
    }

})(jQuery);
