$(function(){
    $('body').stellar({
        scrollProperty: 'scroll',
        verticalOffset: 0,
        horizontalOffset: 0,
        responsive: false,
        parallaxBackgrounds: true,
        parallaxElements: true,
        hideDistantElements: true,
        horizontalScrolling: false,

        // Customise how elements are shown and hidden
        hideElement: function ($elem) {
            $elem.hide();
        },
        showElement: function ($elem) {
            $elem.show();
        }
    });
})